import React from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Logo from  './../../assets/img/LogoAbu.png'
class LNavbar extends React.Component {
    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = {
        isOpen: false
      };
    }

    toggle () {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    render() {
      return (
        <div>
          <Navbar className="navbar-dark bg-abu  fixed-top" expand="lg">
            <Container>
              <NavLink to="/" className="navbar-brand">
                <img className="ml-2 mr-3" style={{height: 30}} src={Logo} alt="logo" /> 
              </NavLink>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem key="navitem-1">
                    <a className="nav-link" href="/#about">
                      <span className=""></span> ¿Quiénes Somos?
                    </a>
                  </NavItem>
                  <NavItem key="navitem-2">
                    <a className="nav-link" href="/#benefits">
                      <span className=""></span> Beneficios
                    </a>
                  </NavItem>
                  <NavItem key="navitem-4">
                    <a className="nav-link" href="/#contact">
                      <span className=""></span> Contacto
                    </a>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </div>
      );
    }
};

export default LNavbar;
