import React from 'react';

const LHeader = () => {
  return (
    <div className="header bg-white intro">
      <div className="text-center-cell">
        <button size="lg" className="btn btn-xs button-header"> <a className="nav-link text-primary" href="#about">CONÓCENOS</a></button>
      </div>
    </div>
  );
};

export default LHeader;
