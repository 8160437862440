import React from 'react';
import {
  Button
} from 'reactstrap';

const LContact = () => {
  return (
    <div className="contact" id="contact">
      <div className="cta bg-abu text-center text-white">
        <h1 className="text-white">CONTÁCTANOS</h1>
        <p className="lead">Si necesitas mas información comunicate con nosotros.</p>
		    <Button href="mailto:valentinasalazar9@gmail.com?subject=ABU Intéres &body= Escribe un mensaje..." color="white" size="lg" className="mt-4">Enviar Correo</Button>
      </div>
    </div>
  );
};

export default LContact;
