import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
const LAbout = (props) => {
  return (
    <div id="about" className="testimonials bg-light">
    	<div className="text-center mb-5">
    		<h2 className="Title-Landing">¿QUIÉNES SOMOS?</h2>
    	</div>
      <Container>
        <Row>
          <Col xs="12" md="6">
            <div className="text-center-cell">
              <p>ABU es una plataforma que tiene como objetivo mejorar la calidad de vida de la población de edad avanzada desde un estado físico, mental y emocional, ofreciendo servicios integrales, actividades, y un espacio para relacionarse en su vida diaria. </p>
              <p>Para nosotros es de vital importancia promover su autonomía personal potencializando sus conocimientos y capacidades.</p>
              <p>El avance de la tecnología y salud conllevan un aumento en la esperanza de vida de la población, por lo que globalmente se evidencia un envejecimiento demográfico, así como el incremento del índice de dependencia que demanda la necesidad de programas sociales para las personas mayores. </p>
              <p>ABU es la herramienta que busca darle un propósito de vida a tus familiares mayores, todo se trata de vivir experiencias...Se puede ser feliz hasta el último día de nuestras vidas  </p>
            </div>
          </Col>
          <Col xs="12" md="6">   
            <iframe title="AbuIntro" width="100%" height="380" src="https://www.youtube.com/embed/MW_SyY-RkZ8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>    
          </Col>
        </Row>
      </Container>
   </div>
  );
};

export default LAbout;
