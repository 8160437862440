import React from 'react';
import LNavbar from './../components/landing/LNavbar';
import LHeader from './../components/landing/LHeader';
import LBenefits from './../components/landing/LBenefits';
import LAbout from './../components/landing/LAbout';
import LContact from './../components/landing/LContact';
import WhatsappButton from './../components/landing/WhatsappButton';

class Landing extends React.Component {
  render() {
    return (
      <>
        <div className="landing">
          <LNavbar />
          <LHeader />
          <LAbout />
          <LBenefits />
          <LContact />
          <WhatsappButton />
        </div>
      </>
    );
  };
};

export default Landing;
