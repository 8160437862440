import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import Benefit from './Benefit';

const LBenefits = () => {
  let fProps = [
    {
      color: "primary",
      icon: "running",
      title: "Actividades",
      text: "Es momento de ejercitar nuestra mente y cuerpo. Te ofrecemos una variedad de actividades para tu disfrute; yoga, baile, meditación, bingo... ¡Tú solo pídelo que lo tenemos! Tu felicidad es la nuestra."
    },
    {
      color: "primary",
      icon: "users",
      title: "Conecta con amigos",
      text: "Agrega a tus amigos y mantente en contacto con ellos en nuestra red social. ¿Conociste a un ABU en una de tus actividades? ¡Agrégalo y hazlo parte de tu red de amigos!"
    }
  ]

  let features = fProps.map((feature, index) => {
    return (
      <Col xs="12" md="6" key={`feature-${index}`}>
        <Benefit {...feature} />
      </Col>
    );
  });

  return (
    <div  id="benefits" className="features">
      <div className="text-center">
    		<h2 className="Title-Landing">BENEFICIOS</h2>
    	</div>
    	<Container>
    		<Row>
          {features}
    		</Row>
    	</Container>
    </div>
  );
};

export default LBenefits;
