import React from 'react'

import WhatsAppWidget from 'react-whatsapp-widget'
import '../../../src/assets/css/whatsapp.css'

const WhatsappButton = () => {
  return <WhatsAppWidget 
            phoneNumber='573147606241' 
            textReplyTime='Envianos un mensaje'
            companyName="ABU"
            sendButton="Enviar"
            message="Hola! 👋🏼 ¿En que podemos ayudarte?'"
            />
}

export default WhatsappButton