import React from 'react';
import Landing from "./views/Landing";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './fontawesome';

const App = () => {
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={() => <Landing />}/>
          <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
